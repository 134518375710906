import {useContext, useEffect, useState} from "react";
import {RealmContext, TokenContext} from "../Store";
import {connect} from "react-redux";
import SwaggerUI from "swagger-ui-react";
import {trackPromise} from "react-promise-tracker";
import BimetaDataService from "../../services/BimetaDataService";
import http from "../../shared/http-common";

function DokuObject() {
    // const [treeItemsState] = useContext(TreeItemsStateContext);
    const [token] = useContext(TokenContext);
    const [realm] = useContext(RealmContext);
    const [bimData , setBimData] = useState('');
    const [classificationsystems, setClassificationsystems] = useState([]);
    // // const [SiteColor] = useContext(SiteColorContext);
    // const [bimProfileSelected, setBimProfileSelected] = useContext(BimProfileSelectedContext);
    const [spec, setSpec] =  useState(null);
    const [, setIsLoading] = useState(false);

    const getApiDoc = async() => {
        return await trackPromise(
            getdocfromserver(token, realm)
        )
    }

    const getdocfromserver = (token, realm) => {
        let formData = new FormData();
        formData.append("realm", realm);

        return http.post(`/oaf/${realm}/api4`, formData, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
    }
    useEffect(() => {
        setIsLoading(true)
        getApiDoc().then(data => {
            setSpec(data.data);
            setIsLoading(false)
        })
    }, []);

    return (
        <>
            <div style={{textAlign: "left"}}>
            <SwaggerUI spec={spec} ></SwaggerUI>
            </div>
        </>
    )
}

export default DokuObject;